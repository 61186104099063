import { defineNuxtPlugin } from '#app'
import { plugin, defaultConfig, ssrComplete } from '@formkit/vue'
import { resetCount } from '@formkit/core'
import config from '/data/www/portal.entercare.nl/entercare/nuxt/formkit.config.ts'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:rendered', (renderContext) => {
    resetCount()
    ssrComplete(nuxtApp.vueApp)
  })
  nuxtApp.vueApp.use(plugin, defaultConfig(config))

})
