import { default as _91slug_936AlH3VHURfMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/academy/[slug].vue?macro=true";
import { default as indexyCbgxJXQ3CMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/academy/index.vue?macro=true";
import { default as _91order_93lApJmx36gfMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account/bestelhistorie/[order].vue?macro=true";
import { default as indexMKMgkhiJ3jMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account/bestelhistorie/index.vue?macro=true";
import { default as bestelhistorieY8a6P73q2uMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account/bestelhistorie.vue?macro=true";
import { default as entercoinsMLPCNYVdvEMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account/entercoins.vue?macro=true";
import { default as indexxsnux9kUcMMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account/index.vue?macro=true";
import { default as ingeschrevenRVxVs8Nc35Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account/ingeschreven.vue?macro=true";
import { default as accountQwhB2vDU2IMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/account.vue?macro=true";
import { default as _91token_93b3VSBEW3GOMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/herstellen/[token].vue?macro=true";
import { default as _91token_93oTh2sm1YX4Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/instellen/[token].vue?macro=true";
import { default as loginBIUEo5SfQrMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/login.vue?macro=true";
import { default as wachtwoord_45vergetenmS9H1mHA8BMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/wachtwoord-vergeten.vue?macro=true";
import { default as before_45afterwT5hj9vBEWMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/before-after.vue?macro=true";
import { default as downloadsolIYlS7hsYMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/downloads.vue?macro=true";
import { default as educatie7cwknZZlY0Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/educatie.vue?macro=true";
import { default as indexKqWhZpIBGEMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/giftshop/[brand]/index.vue?macro=true";
import { default as indexpqLk7KsJ9XMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/giftshop/index.vue?macro=true";
import { default as indexsscXdb32xuMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/index.vue?macro=true";
import { default as veelgestelde_45vragentvElKMO0T0Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/veelgestelde-vragen.vue?macro=true";
import { default as indexvYFv3FCXeGMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/[subcategory]/index.vue?macro=true";
import { default as indexIqqFXCi1WaMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/index.vue?macro=true";
import { default as before_45afterS7804HU19VMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/before-after.vue?macro=true";
import { default as detailsgx7AensqtgMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/details.vue?macro=true";
import { default as downloadstc0o4oV8t5Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/downloads.vue?macro=true";
import { default as gebruik938GKQdeYFMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/gebruik.vue?macro=true";
import { default as index05Wk0Y1S3LMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/index.vue?macro=true";
import { default as _91slug_93gR3Ectlqf0Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug].vue?macro=true";
import { default as indexfxhg6Ph0K6Meta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/index.vue?macro=true";
import { default as bedanktb5siqrduyXMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/winkelwagen/bedankt.vue?macro=true";
import { default as bezorgingzpfaIYjEiXMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/winkelwagen/bezorging.vue?macro=true";
import { default as indexlfxq51ZMeOMeta } from "/data/www/portal.entercare.nl/entercare/nuxt/pages/winkelwagen/index.vue?macro=true";
export default [
  {
    name: _91slug_936AlH3VHURfMeta?.name ?? "academy-slug",
    path: _91slug_936AlH3VHURfMeta?.path ?? "/academy/:slug()",
    meta: _91slug_936AlH3VHURfMeta || {},
    alias: _91slug_936AlH3VHURfMeta?.alias || [],
    redirect: _91slug_936AlH3VHURfMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/academy/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyCbgxJXQ3CMeta?.name ?? "academy",
    path: indexyCbgxJXQ3CMeta?.path ?? "/academy",
    meta: indexyCbgxJXQ3CMeta || {},
    alias: indexyCbgxJXQ3CMeta?.alias || [],
    redirect: indexyCbgxJXQ3CMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/academy/index.vue").then(m => m.default || m)
  },
  {
    path: accountQwhB2vDU2IMeta?.path ?? "/account",
    children: [
  {
    path: bestelhistorieY8a6P73q2uMeta?.path ?? "bestelhistorie",
    children: [
  {
    name: _91order_93lApJmx36gfMeta?.name ?? "account-bestelhistorie-order",
    path: _91order_93lApJmx36gfMeta?.path ?? ":order()",
    meta: _91order_93lApJmx36gfMeta || {},
    alias: _91order_93lApJmx36gfMeta?.alias || [],
    redirect: _91order_93lApJmx36gfMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account/bestelhistorie/[order].vue").then(m => m.default || m)
  },
  {
    name: indexMKMgkhiJ3jMeta?.name ?? "account-bestelhistorie",
    path: indexMKMgkhiJ3jMeta?.path ?? "",
    meta: indexMKMgkhiJ3jMeta || {},
    alias: indexMKMgkhiJ3jMeta?.alias || [],
    redirect: indexMKMgkhiJ3jMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account/bestelhistorie/index.vue").then(m => m.default || m)
  }
],
    name: bestelhistorieY8a6P73q2uMeta?.name ?? undefined,
    meta: bestelhistorieY8a6P73q2uMeta || {},
    alias: bestelhistorieY8a6P73q2uMeta?.alias || [],
    redirect: bestelhistorieY8a6P73q2uMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account/bestelhistorie.vue").then(m => m.default || m)
  },
  {
    name: entercoinsMLPCNYVdvEMeta?.name ?? "account-entercoins",
    path: entercoinsMLPCNYVdvEMeta?.path ?? "entercoins",
    meta: entercoinsMLPCNYVdvEMeta || {},
    alias: entercoinsMLPCNYVdvEMeta?.alias || [],
    redirect: entercoinsMLPCNYVdvEMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account/entercoins.vue").then(m => m.default || m)
  },
  {
    name: indexxsnux9kUcMMeta?.name ?? "account",
    path: indexxsnux9kUcMMeta?.path ?? "",
    meta: indexxsnux9kUcMMeta || {},
    alias: indexxsnux9kUcMMeta?.alias || [],
    redirect: indexxsnux9kUcMMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: ingeschrevenRVxVs8Nc35Meta?.name ?? "account-ingeschreven",
    path: ingeschrevenRVxVs8Nc35Meta?.path ?? "ingeschreven",
    meta: ingeschrevenRVxVs8Nc35Meta || {},
    alias: ingeschrevenRVxVs8Nc35Meta?.alias || [],
    redirect: ingeschrevenRVxVs8Nc35Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account/ingeschreven.vue").then(m => m.default || m)
  }
],
    name: accountQwhB2vDU2IMeta?.name ?? undefined,
    meta: accountQwhB2vDU2IMeta || {},
    alias: accountQwhB2vDU2IMeta?.alias || [],
    redirect: accountQwhB2vDU2IMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91token_93b3VSBEW3GOMeta?.name ?? "auth-herstellen-token",
    path: _91token_93b3VSBEW3GOMeta?.path ?? "/auth/herstellen/:token()",
    meta: _91token_93b3VSBEW3GOMeta || {},
    alias: _91token_93b3VSBEW3GOMeta?.alias || [],
    redirect: _91token_93b3VSBEW3GOMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/herstellen/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93oTh2sm1YX4Meta?.name ?? "auth-instellen-token",
    path: _91token_93oTh2sm1YX4Meta?.path ?? "/auth/instellen/:token()",
    meta: _91token_93oTh2sm1YX4Meta || {},
    alias: _91token_93oTh2sm1YX4Meta?.alias || [],
    redirect: _91token_93oTh2sm1YX4Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: loginBIUEo5SfQrMeta?.name ?? "auth-login",
    path: loginBIUEo5SfQrMeta?.path ?? "/auth/login",
    meta: loginBIUEo5SfQrMeta || {},
    alias: loginBIUEo5SfQrMeta?.alias || [],
    redirect: loginBIUEo5SfQrMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenmS9H1mHA8BMeta?.name ?? "auth-wachtwoord-vergeten",
    path: wachtwoord_45vergetenmS9H1mHA8BMeta?.path ?? "/auth/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenmS9H1mHA8BMeta || {},
    alias: wachtwoord_45vergetenmS9H1mHA8BMeta?.alias || [],
    redirect: wachtwoord_45vergetenmS9H1mHA8BMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/auth/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: before_45afterwT5hj9vBEWMeta?.name ?? "before-after",
    path: before_45afterwT5hj9vBEWMeta?.path ?? "/before-after",
    meta: before_45afterwT5hj9vBEWMeta || {},
    alias: before_45afterwT5hj9vBEWMeta?.alias || [],
    redirect: before_45afterwT5hj9vBEWMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/before-after.vue").then(m => m.default || m)
  },
  {
    name: downloadsolIYlS7hsYMeta?.name ?? "downloads",
    path: downloadsolIYlS7hsYMeta?.path ?? "/downloads",
    meta: downloadsolIYlS7hsYMeta || {},
    alias: downloadsolIYlS7hsYMeta?.alias || [],
    redirect: downloadsolIYlS7hsYMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/downloads.vue").then(m => m.default || m)
  },
  {
    name: educatie7cwknZZlY0Meta?.name ?? "educatie",
    path: educatie7cwknZZlY0Meta?.path ?? "/educatie",
    meta: educatie7cwknZZlY0Meta || {},
    alias: educatie7cwknZZlY0Meta?.alias || [],
    redirect: educatie7cwknZZlY0Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/educatie.vue").then(m => m.default || m)
  },
  {
    name: indexKqWhZpIBGEMeta?.name ?? "giftshop-brand",
    path: indexKqWhZpIBGEMeta?.path ?? "/giftshop/:brand()",
    meta: indexKqWhZpIBGEMeta || {},
    alias: indexKqWhZpIBGEMeta?.alias || [],
    redirect: indexKqWhZpIBGEMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/giftshop/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpqLk7KsJ9XMeta?.name ?? "giftshop",
    path: indexpqLk7KsJ9XMeta?.path ?? "/giftshop",
    meta: indexpqLk7KsJ9XMeta || {},
    alias: indexpqLk7KsJ9XMeta?.alias || [],
    redirect: indexpqLk7KsJ9XMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/giftshop/index.vue").then(m => m.default || m)
  },
  {
    name: indexsscXdb32xuMeta?.name ?? "index",
    path: indexsscXdb32xuMeta?.path ?? "/",
    meta: indexsscXdb32xuMeta || {},
    alias: indexsscXdb32xuMeta?.alias || [],
    redirect: indexsscXdb32xuMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: veelgestelde_45vragentvElKMO0T0Meta?.name ?? "veelgestelde-vragen",
    path: veelgestelde_45vragentvElKMO0T0Meta?.path ?? "/veelgestelde-vragen",
    meta: veelgestelde_45vragentvElKMO0T0Meta || {},
    alias: veelgestelde_45vragentvElKMO0T0Meta?.alias || [],
    redirect: veelgestelde_45vragentvElKMO0T0Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/veelgestelde-vragen.vue").then(m => m.default || m)
  },
  {
    name: indexvYFv3FCXeGMeta?.name ?? "webshop-brand-subcategory",
    path: indexvYFv3FCXeGMeta?.path ?? "/webshop/:brand()/:subcategory()",
    meta: indexvYFv3FCXeGMeta || {},
    alias: indexvYFv3FCXeGMeta?.alias || [],
    redirect: indexvYFv3FCXeGMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: indexIqqFXCi1WaMeta?.name ?? "webshop-brand",
    path: indexIqqFXCi1WaMeta?.path ?? "/webshop/:brand()",
    meta: indexIqqFXCi1WaMeta || {},
    alias: indexIqqFXCi1WaMeta?.alias || [],
    redirect: indexIqqFXCi1WaMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/index.vue").then(m => m.default || m)
  },
  {
    path: _91slug_93gR3Ectlqf0Meta?.path ?? "/webshop/:brand()/product/:slug()",
    children: [
  {
    name: before_45afterS7804HU19VMeta?.name ?? "webshop-brand-product-slug-before-after",
    path: before_45afterS7804HU19VMeta?.path ?? "before-after",
    meta: before_45afterS7804HU19VMeta || {},
    alias: before_45afterS7804HU19VMeta?.alias || [],
    redirect: before_45afterS7804HU19VMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/before-after.vue").then(m => m.default || m)
  },
  {
    name: detailsgx7AensqtgMeta?.name ?? "webshop-brand-product-slug-details",
    path: detailsgx7AensqtgMeta?.path ?? "details",
    meta: detailsgx7AensqtgMeta || {},
    alias: detailsgx7AensqtgMeta?.alias || [],
    redirect: detailsgx7AensqtgMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: downloadstc0o4oV8t5Meta?.name ?? "webshop-brand-product-slug-downloads",
    path: downloadstc0o4oV8t5Meta?.path ?? "downloads",
    meta: downloadstc0o4oV8t5Meta || {},
    alias: downloadstc0o4oV8t5Meta?.alias || [],
    redirect: downloadstc0o4oV8t5Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/downloads.vue").then(m => m.default || m)
  },
  {
    name: gebruik938GKQdeYFMeta?.name ?? "webshop-brand-product-slug-gebruik",
    path: gebruik938GKQdeYFMeta?.path ?? "gebruik",
    meta: gebruik938GKQdeYFMeta || {},
    alias: gebruik938GKQdeYFMeta?.alias || [],
    redirect: gebruik938GKQdeYFMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/gebruik.vue").then(m => m.default || m)
  },
  {
    name: index05Wk0Y1S3LMeta?.name ?? "webshop-brand-product-slug",
    path: index05Wk0Y1S3LMeta?.path ?? "",
    meta: index05Wk0Y1S3LMeta || {},
    alias: index05Wk0Y1S3LMeta?.alias || [],
    redirect: index05Wk0Y1S3LMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug]/index.vue").then(m => m.default || m)
  }
],
    name: _91slug_93gR3Ectlqf0Meta?.name ?? undefined,
    meta: _91slug_93gR3Ectlqf0Meta || {},
    alias: _91slug_93gR3Ectlqf0Meta?.alias || [],
    redirect: _91slug_93gR3Ectlqf0Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/[brand]/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfxhg6Ph0K6Meta?.name ?? "webshop",
    path: indexfxhg6Ph0K6Meta?.path ?? "/webshop",
    meta: indexfxhg6Ph0K6Meta || {},
    alias: indexfxhg6Ph0K6Meta?.alias || [],
    redirect: indexfxhg6Ph0K6Meta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/webshop/index.vue").then(m => m.default || m)
  },
  {
    name: bedanktb5siqrduyXMeta?.name ?? "winkelwagen-bedankt",
    path: bedanktb5siqrduyXMeta?.path ?? "/winkelwagen/bedankt",
    meta: bedanktb5siqrduyXMeta || {},
    alias: bedanktb5siqrduyXMeta?.alias || [],
    redirect: bedanktb5siqrduyXMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/winkelwagen/bedankt.vue").then(m => m.default || m)
  },
  {
    name: bezorgingzpfaIYjEiXMeta?.name ?? "winkelwagen-bezorging",
    path: bezorgingzpfaIYjEiXMeta?.path ?? "/winkelwagen/bezorging",
    meta: bezorgingzpfaIYjEiXMeta || {},
    alias: bezorgingzpfaIYjEiXMeta?.alias || [],
    redirect: bezorgingzpfaIYjEiXMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/winkelwagen/bezorging.vue").then(m => m.default || m)
  },
  {
    name: indexlfxq51ZMeOMeta?.name ?? "winkelwagen",
    path: indexlfxq51ZMeOMeta?.path ?? "/winkelwagen",
    meta: indexlfxq51ZMeOMeta || {},
    alias: indexlfxq51ZMeOMeta?.alias || [],
    redirect: indexlfxq51ZMeOMeta?.redirect || undefined,
    component: () => import("/data/www/portal.entercare.nl/entercare/nuxt/pages/winkelwagen/index.vue").then(m => m.default || m)
  }
]