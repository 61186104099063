import {provideApolloClient} from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  provideApolloClient(useApollo().clients.default);
  // Get current request event
  const event = useRequestEvent();

  nuxtApp.hook('apollo:headers', (headers) => {
    const req = event?.node?.req;

    if (req) {
      const headerKey = req.headers['cf-ray'] ? 'cf-connecting-ip' : 'x-forwarded-for';

      headers.value = {
        ...headers.value,
        'x-forwarded-for': req.headers[headerKey] || req.connection.remoteAddress || req.socket.remoteAddress,
        'x-tenant': req.headers['x-forwarded-host'] || req.headers?.host?.split(':')[0],
      };
    }
  });
});
