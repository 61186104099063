<template>
  <svg
    width="28"
    height="39"
    viewBox="0 0 28 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3333 10.5217V0.331055H1.75C0.729167 0.331055 0 1.15529 0 2.1294V36.8973C0 37.9464 0.729167 38.6957 1.75 38.6957H26.25C27.1979 38.6957 28 37.9464 28 36.8973V12.32H18.0833C17.0625 12.32 16.3333 11.5707 16.3333 10.5217ZM21.0729 26.7817H16.3333V32.7761C16.3333 33.3756 15.75 33.975 15.1667 33.975H12.8333C12.1771 33.975 11.6667 33.3756 11.6667 32.7761V26.7817H6.85417C5.83333 26.7817 5.32292 25.4329 6.05208 24.6836L13.0521 17.4902C13.5625 17.0406 14.3646 17.0406 14.875 17.4902L21.875 24.6836C22.6042 25.4329 22.0938 26.7817 21.0729 26.7817ZM27.4896 8.1988L20.3438 0.855571C19.9792 0.555847 19.5417 0.331055 19.1042 0.331055H18.6667V9.92221H28V9.47262C28 9.02304 27.7812 8.57345 27.4896 8.1988Z"
      fill="currentColor"
    />
  </svg>
</template>
