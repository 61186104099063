<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
useHead(computed(() => ({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Entercare` : 'Entercare';
  },
  charset: 'utf-8',
})));
</script>
