<template>
  <FormKit
    readonly
    type="text"
    prefix-icon="link"
    prefix-icon-class="w-10"
    :value="context.value"
  >
    <template #suffixIcon>
      <button
        class="rounded-full bg-black p-4 text-white hover:bg-primary hover:text-black"
        @click="copy"
      >
        Kopiëer
      </button>
    </template>
  </FormKit>
</template>

<script lang="ts" setup>
import {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

function copy() {
  navigator.clipboard.writeText(props.context.value);
  toast.success('Link gekopiëerd.');
}
</script>
