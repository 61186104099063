import plugin_vue3_6PC7TIdvuh from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@pinia+nuxt@0.4.8_rollup@3.24.0_typescript@4.7.3_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_LMtevzTa69 from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@18.16.18_eslint@8.42.0_rollup@3.24.0_stylelint@15.7.0_typescript@4.7.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/portal.entercare.nl/entercare/nuxt/.nuxt/components.plugin.mjs";
import unhead_V9DsplZiCa from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@18.16.18_eslint@8.42.0_rollup@3.24.0_stylelint@15.7.0_typescript@4.7.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HydbhtGLAw from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@18.16.18_eslint@8.42.0_rollup@3.24.0_stylelint@15.7.0_typescript@4.7.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_UBWHsNKHro from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@18.16.18_eslint@8.42.0_rollup@3.24.0_stylelint@15.7.0_typescript@4.7.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_qj2Z0wVACH from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/nuxt-bugsnag@6.2.1_rollup@3.24.0/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import composition_Ruqt0HdQX4 from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_rollup@3.24.0_vue-router@4.2.2_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_O08JtIVewG from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_rollup@3.24.0_vue-router@4.2.2_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/portal.entercare.nl/entercare/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_oZL3ZGfKYI from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.0.0-beta.2_react-dom@18.2.0_react@18.2.0_rollup@3.24.0_typescript@4.7.3_vue@3.3.4/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.mjs";
import plugin_oftBH5LrqM from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.5.0_next-auth@4.21.1_rollup@3.24.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_K96BE77eRH from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@18.16.18_eslint@8.42.0_rollup@3.24.0_stylelint@15.7.0_typescript@4.7.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_1ILFkzpYSr from "/data/www/portal.entercare.nl/entercare/nuxt/plugins/apollo.ts";
import portal_vue_h7YCDNO0gl from "/data/www/portal.entercare.nl/entercare/nuxt/plugins/portal-vue.ts";
import apollo_5X3WTABz2g from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.0.0-beta.10_@unhead+vue@1.1.27_next@13.4.6_nuxt@3.5.3_reac_wtdd7rbvx6vjrhitt4nvlytipe/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_fFTOlAtDFN from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.0.0-beta.10_@unhead+vue@1.1.27_next@13.4.6_nuxt@3.5.3_reac_wtdd7rbvx6vjrhitt4nvlytipe/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import apollo_98Di1AAMb5 from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-beta.10_@unhead+vue@1.1.27_nuxt@3.5.3_react-dom@18.2.0_reac_46j7oo4pbi6p6znrta2kqnlxwi/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import errors_lxSxScztq7 from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-beta.10_@unhead+vue@1.1.27_nuxt@3.5.3_rollup@3.24.0_tailwind_nw32p7be33j745xfp3qx5d5o5e/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_zPK4nZJLEA from "/data/www/portal.entercare.nl/entercare/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-beta.10_@unhead+vue@1.1.27_nuxt@3.5.3_rollup@3.24.0_tailwind_nw32p7be33j745xfp3qx5d5o5e/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
export default [
  plugin_vue3_6PC7TIdvuh,
  revive_payload_client_LMtevzTa69,
  components_plugin_KR1HBZs4kY,
  unhead_V9DsplZiCa,
  router_HydbhtGLAw,
  prefetch_client_UBWHsNKHro,
  plugin_qj2Z0wVACH,
  composition_Ruqt0HdQX4,
  i18n_O08JtIVewG,
  formkitPlugin_pZqjah0RUG,
  plugin_oZL3ZGfKYI,
  plugin_oftBH5LrqM,
  chunk_reload_client_K96BE77eRH,
  apollo_1ILFkzpYSr,
  portal_vue_h7YCDNO0gl,
  apollo_5X3WTABz2g,
  auth_fFTOlAtDFN,
  apollo_98Di1AAMb5,
  errors_lxSxScztq7,
  i18n_zPK4nZJLEA
]