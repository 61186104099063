// formkit.config.ts
import {nl} from '@formkit/i18n';
import {createInput, DefaultConfigOptions} from '@formkit/vue';
import UploadFile from '~/components/formkit/UploadFile.vue';
import Copy from '~/components/formkit/Copy.vue';
import {generateClasses} from '@formkit/themes';

const config: DefaultConfigOptions = {
  locales: {nl},
  locale: 'nl',
  inputs: {
    uploadFile: createInput(UploadFile, {
      family: 'file',
    }),
    copy: createInput(Copy, {
      family: 'text',
    }),
  },
  config: {
    classes: generateClasses({
      global: {
        help: 'text-xs',
        outer: 'formkit-disabled:opacity-50 w-full',
        form: 'space-y-3',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red',
        inner: `
          formkit-invalid:border-red
          formkit-invalid:focus-within:border-secondary-900
          focus-within:border-secondary-900
          formkit-disabled:border-black-400
        `,
        input: 'bg-gray-500 border border-gray-200 py-2.5 px-4 w-full rounded-xl dark:text-black',
        options: 'flex items-center gap-x-2.5',
      },
      text: {
        inner: 'bg-gray-500 pl-5 items-center gap-2 flex w-full rounded-full dark:text-black',
        input: '$reset appearance-none text-lg bg-gray-500 items-center w-full rounded-full dark:text-black',
      },
      'family:box': {
        input: '$reset mr-1 accent-secondary-800',
        label: '$reset text-sm',
        legend: '$reset font-bold',
        wrapper: 'flex mt-4 gap-0.5',
      },
      button: {
        outer: '$reset',
        input: '$reset btn btn-primary',
      },
      file: {
        input: '$reset absolute inset-0 opacity-0 cursor-pointer',
        noFiles: '$reset hidden',
      },
      search: {
        input: 'bg-[url(\'/assets/images/search.svg\')] bg-no-repeat bg-right bg-origin-content pr-3.5',
      },
      select: {
        input: 'hover:border-primary-500 focus:border-text-highlight focus:ring-0 transition border-card-border rounded-xl py-4 px-2.5 w-full px-3 outline-none formkit-disabled:text-black-400 text-black-900 placeholder-black-500 formkit-disabled:bg-black-300 appearance-none bg-[url(\'/assets/images/angle-down.svg\')] bg-no-repeat bg-right bg-origin-content pr-3.5',
      },
      submit: {
        outer: '$reset',
        input: '$reset btn btn-secondary',
      },
      textarea: {
        input: 'h-32',
      },
    }),
  },
};

export default config;
