<template>
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1234 4.37719L13.6252 0.878438C13.0594 0.316031 12.2953 0 11.5031 0H3C1.34297 0 0 1.34297 0 3L0.000304688 21C0.000304688 22.6566 1.34327 24 3.0003 24H15C16.65 24 18 22.65 18 21V6.49688C18 5.70469 17.6859 4.94062 17.1234 4.37719ZM15.75 21C15.75 21.4142 15.4142 21.75 15 21.75H3.00094C2.58666 21.75 2.25094 21.4142 2.25094 21L2.25 3.00609C2.25 2.59191 2.58581 2.25609 3 2.25609H10.5V6C10.5 6.82828 11.1717 7.5 12 7.5H15.7078V21H15.75ZM10.0922 13.6875C9.87281 13.6875 9.66792 13.7972 9.54609 13.9797L7.6875 16.7719L7.14 15.9502C7.01719 15.7641 6.81094 15.6562 6.59531 15.6562C6.37584 15.6562 6.17095 15.7659 6.04922 15.9485L3.86156 19.2297C3.72727 19.4311 3.7148 19.69 3.82897 19.9033C3.94172 20.1187 4.16437 20.25 4.36406 20.25H13.5516C13.7936 20.25 14.016 20.1167 14.13 19.9034C14.2442 19.69 14.2317 19.4313 14.0974 19.2298L10.5973 13.9798C10.5188 13.7953 10.3125 13.6875 10.0922 13.6875ZM6 13.5C6.82828 13.5 7.5 12.8283 7.5 12C7.5 11.1717 6.82969 10.5 6 10.5C5.17031 10.5 4.5 11.1703 4.5 12C4.5 12.8297 5.17031 13.5 6 13.5Z"
      fill="currentColor"
    />
  </svg>
</template>
