<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5531 14.4898C13.853 14.847 13.8063 15.3786 13.4498 15.6785C13.2894 15.8117 13.0996 15.875 12.9062 15.875C12.6656 15.875 12.4267 15.7728 12.2597 15.574L6.99996 9.31133L1.74058 15.5727C1.57289 15.7731 1.33382 15.875 1.093 15.875C0.901297 15.875 0.709098 15.8102 0.550895 15.6772C0.194411 15.3773 0.147653 14.8455 0.447606 14.4886L5.89957 8L0.447711 1.51157C0.147793 1.15438 0.194481 0.622816 0.551001 0.322933C0.907028 0.0223471 1.43965 0.0684018 1.73988 0.425941L6.99996 6.68867L12.2593 0.427347C12.5598 0.0698079 13.0915 0.0241048 13.448 0.324058C13.8045 0.624011 13.8512 1.15585 13.5513 1.51269L8.10035 8L13.5531 14.4898Z"
      fill="currentColor"
    />
  </svg>
</template>
